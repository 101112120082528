<template>
    <el-container style="height:100%">
        <el-aside width="400px" style="padding:5px;">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText"></el-input>
            <el-tree ref="tree" :data="treeData" :props="{ children: 'subNodes', label: 'name'}"
                     node-key="id" @node-click="handleNodeClick"
                     :filter-node-method="filterNode">
                <template slot-scope="{ node, data }">
                    <span :class="data.type == 1 ? 'el-icon-office-building' : 'el-icon-s-flag'">
                        {{data.name}}
                    </span>
                </template>
            </el-tree>
        </el-aside>
        <el-main>
            <div class="x-page-container" ref="resize">
                <!-- 查询区 -->
                <div class="x-page-search">
                    <x-search-item label="姓名">
                        <el-input v-model="search.name" size="small"/>
                    </x-search-item>
                    <x-search-item label="账号">
                        <el-input v-model="search.account" size="small"/>
                    </x-search-item>
                    <x-search-item label="状态">
                        <x-selector-one v-model="search.status" size="small" dictType="T_USER_STATUS"/>
                    </x-search-item>
                    <div class="x-search-item">
                        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                        <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
                    </div>
                </div>
                <!-- 批量按钮区 -->
                <div class="x-page-btn">
                    <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
<!--                    <el-button type="success" size="mini" round @click="handleResetAllPwd">重置所有密码</el-button>-->
                    <el-popconfirm :title="'是否变更所有选中用户部门至->' + organName" @confirm="handleChangeUserOrganBatch">
                        <el-button slot="reference" size="mini" type="warning" round>批量变更用户组织</el-button>
                    </el-popconfirm>
                    <div style="width:300px;display: flex;margin-left: 10px">
                        <div style="margin-top: 15px; ">
                            目标部门：
                        </div>
                        <x-organ-selector v-model="organName" size="small" usable="dept" @selected="deptSelected"
                                          :sels="organId"></x-organ-selector>
                    </div>
                </div>
                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="40" fixed/>
                    <el-table-column show-overflow-tooltip width="150" prop="name" label="姓名" fixed/>
                    <el-table-column show-overflow-tooltip width="150" prop="account" label="账号"/>
                    <el-table-column width="120" prop="phone" label="手机号"/>
                    <el-table-column width="85" prop="securityClassification" label="用户密级">
                        <x-dict-show slot-scope="{row}" :code="row.securityClassification"
                                     dictType="T_SECURITY_CLASSIFICATION"/>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip width="250" prop="organName" label="机构"/>
                    <el-table-column show-overflow-tooltip prop="deptName" label="部门"/>
                    <el-table-column width="100" label="状态">
                        <x-dict-show slot-scope="{row}" :code="row.status" dictType="T_USER_STATUS"/>
                    </el-table-column>
                    <el-table-column width="340" label="操作" fixed="right">
                        <template slot-scope="{ row, $index }">
                            <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                                <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                            </el-popconfirm>
                            <el-popconfirm v-if="row.status === 'locked'" title="确认删是否解锁吗？" @confirm="unlock(row.id)">
                                <el-button type="success" size="mini" round slot="reference">解锁</el-button>
                            </el-popconfirm>
                            <el-popconfirm title="重置密码后不可恢复，确认重置吗？" @confirm="handleResetPwd(row.id)">
                              <el-button type="danger" size="mini" round slot="reference">重置密码</el-button>
                            </el-popconfirm>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
                <!-- 编辑页区域 -->
                <edit ref="edit" :editor="editor" @submitted="refresh"/>
            </div>
        </el-main>
    </el-container>
</template>
<script>
    import * as service from "@/service/system/user";
    import {mainTree} from "@/service/system/organ";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/system/user/UserEdit";

    export default {
        name: "UserList",
        mixins: [XTableBase],
        components: {Edit},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    name: '',
                    status: '',
                    account: '',
                    organCode: '',
                    deptCode: '',
                },
                editor: {
                    visible: false,
                    id: null,
                    organId: null
                },
                filterText: '',
                treeData: [],
                organId: "",
                organName: "",
            }
        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        mounted() {
            mainTree().then((response) => {
                this.treeData = response.data;
            })
        },
        methods: {
            handleResetAllPwd() {
              this.service.resetAllPwd().then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
              });
            },
            handleResetPwd(id) {
              this.service.resetPwd(id).then((response) => {
                //提示成功
                this.$message.success(response.msg);
                //刷新列表
                this.refresh();
              });
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            handleNodeClick(data) {
                if (data.type === '1') {
                    this.search.organCode = data.organCode;
                    this.search.deptCode = '';
                } else {
                    this.search.deptCode = data.deptCode;
                    this.search.organCode = '';
                }
                this.editor.organId = data.id;
                this.refresh();
            },
            deptSelected(datas) {
                let organ = [];
                if (datas && datas.length > 0) {
                    organ = datas[0];
                }
                this.organId = organ.id || "";
                this.organName = organ.name || "";
            },
            handleChangeUserOrganBatch() {
                if (!this.organId) {
                    this.$message.warning("请选择一个目标部门");
                    return false;
                }
                if (!this.sels || this.sels.length <= 0) {
                    this.$message.warning("请至少选择一个用户");
                    return false;
                }
                let ids = this.sels.map((obj) => {
                    return obj.id;
                });
                service.changeUserOrganBatch({ids: ids, organId: this.organId}).then((response) => {
                    this.$message.info(response.msg);
                    this.refresh();
                });
            },
            unlock(id){
                service.unlock(id).then(res =>{
                    this.$message.success("解锁成功！");
                    this.refresh();
                })
            }
        }
    }
</script>

<style scoped>
</style>
