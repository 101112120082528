<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" center append-to-body
               width="1000">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="姓名" prop="name" class="w50">
                <el-input v-model="form.name" size="small" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="account" class="w50">
                <el-input v-model="form.account" size="small" :disabled="form.id" maxlength="50" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="phone" class="w50">
                <el-input v-model="form.phone" size="small" maxlength="30" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="所属机构" class="w50">
                <el-input v-model="form.organName" size="small" disabled></el-input>
            </el-form-item>
            <el-form-item label="用户状态" prop="status" class="w50">
                <x-radio-group v-model="form.status" size="small" dictType="T_USER_STATUS"/>
            </el-form-item>
            <el-form-item label="所属部门" prop="deptName" class="w50">
                <x-organ-selector v-model="form.deptName" size="small" @selected="deptSelected"
                                  :sels="form.userOrganId" style="width: 100%"></x-organ-selector>
            </el-form-item>
            <el-form-item label="用户密级" prop="securityClassification" class="w50">
                <x-radio-group v-model="form.securityClassification" size="small" dictType="T_SECURITY_CLASSIFICATION"/>
            </el-form-item>
            <el-form-item label="数据密级" prop="classification" class="w50">
                <x-radio-group v-model="form.classification" size="small" dictType="T_SECURITY_CLASSIFICATION"/>
            </el-form-item>
            <el-form-item label="收粮地点" prop="sldd" class="w50">
                <x-selector-one v-model="form.sldd" dictType="T_LSGX_SLDD"/>
            </el-form-item>
            <el-form-item label="用户类型" prop="userOrganLx" class="w50">
                <x-radio-group v-model="form.userOrganLx" size="small" dictType="T_USER_MD_OR_SH"/>
            </el-form-item>
            <el-form-item label="角色" prop="roleIds" class="line">
                <el-checkbox-group v-model="form.roleIds">
                    <el-checkbox v-for="role in roles"
                                 :key="role.id"
                                 :label="role.id"
                                 :disabled="role.status === 'false'">{{ role.name }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add" :loading="loading">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
import * as service from "@/service/system/user";
import {getRoleOptions} from "@/service/system/role";
import XEditBase from "@/components/x/XEditBase";
import {ruleBuilder} from '@/util/validate';

export default {
    name: 'UserEditor',
    mixins: [XEditBase],
    beforeCreate() {
        let {required, mobilePhone} = ruleBuilder;
        this.rules = {
            name: [required()], // 用户名称
            account: [required()],
            phone: [required(), mobilePhone()],
            deptName: [required('string', ['blur', 'change'])],
            securityClassification: [required()],
            classification: [required()],
            userOrganLx:[required('string', ['blur', 'change'])]
        }
        this.titlePrefix = "用户信息";
        this.defaultForm = {
            id: null,
            name: "", // 角色名称
            account: "", // 标题
            phone: "", // 描述
            orgCode: "",
            organName: "",
            deptCode: "",
            deptName: "",
            status: "enable",
            userOrganId: "",
            roleIds: [],
            userGroupIds: [],
            securityClassification: "0",
            classification: "0",
            sldd: "001",
            userOrganLx:"1",
        };
        this.addService = service.add;
        this.updateService = service.update;
        this.getUpdateService = service.get;
    },
    beforeMount() {
        getRoleOptions().then((response) => {
            this.roles = response.data;
        });
    },
    data() {
        return {
            roles: []
        }
    },
    methods: {
        deptSelected(datas) {
            let organ = [];
            if (datas && datas.length > 0) {
                organ = datas[0];
            }
            this.form.orgCode = organ.orgCode || "";
            this.form.organName = organ.organName || "";
            this.form.deptCode = organ.deptCode || "";
            this.form.deptName = organ.deptName || "";
            this.form.userOrganId = organ.id || "";
        }
    }
}
</script>

<style scoped>

</style>
